import React, {useCallback, useEffect, useRef, useState} from "react"
import { css } from "@emotion/core";
import {useDispatch, useSelector} from "react-redux"
import {Form, Container} from "react-bootstrap"
import {useFormik} from "formik"
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {AllDeliveryTypes, DeliveryTypes} from "lib/helpers/constants"
import selectors from "lib/redux/selectors";
import { formatPrice } from "lib/helpers/utils";
import cssVars from "styles/variables.module.scss";
import { mq } from "styles";
import actions from "../../lib/redux/actions"
import SummaryTotal from "../elements/summary-total"
import useFormikEffect from "../useFormikEffect"
import PickupPointSearch from "./pickup-point-search"
import AddressSelection from "./address-selection"

const styles = {
  formContainer: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }),
  form: css({
    overflowY: 'auto',
    flexGrow: 1,
    ".price": {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
  }),
  paymentMethodItem: css({
    display: "flex",
    flexDirection: "column",
    minHeight: "2.5rem",
    ".custom-control-label": {
      fontSize: '1rem',
      fontWeight: 400,
      "&::before, &::after": {
        top: "0",
        bottom: "0",
        margin: "auto 0",
      },
    },
    ".custom-control": {
      display: "inline-block",
      marginRight: "1rem",
    },
    ".note": {
      paddingLeft: "1.5rem",
    }
  }),
  paymentMethods: css({
    listStyle: "none",
    padding: 0,
    margin: 0,
    marginBottom: '1rem',
    ".badge": {
      fontSize: "0.9rem",
    },
    ".disabled": {
      opacity: 0.7,
    },
  }),
};

const schema = yup.object({
  paymentMethod: yup
    .string()
    .oneOf(['online', 'offline'])
    .required(),
});

export default function FlowStepPaymentMethod({
                                               stepState,
                                               updateStepState,
                                               onFormikProps,
                                               onNextStep,
                                             }) {
  const { t } = useTranslation();

  const {
    paymentMethod,
  } = stepState;

  const handleValidSubmit = async () => {
    onNextStep();
    return false;
  }

  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    submitForm,
    isSubmitting,
    values,
    touched,
    isValid,
    errors,
  } = useFormik({
    initialValues: {
      paymentMethod
    },
    onSubmit: handleValidSubmit,
    validationSchema: schema,
  })

  const availablePaymentMethods = useSelector(selectors.deliveries.availablePaymentMethods)

  //TODO: skip to next step or always ask?
  /*if (availablePaymentMethods.length === 1) {
    onNextStep()
  }*/

  const handleFormChange = useCallback(({ nextValues }) => {
    updateStepState(nextValues);
  }, [updateStepState]);

  useFormikEffect(handleFormChange, { values })

  onFormikProps({
    submitForm,
    isValid,
    isSubmitting,
    submitLabel: t("flow.continue"),
  });

  return (
    <div css={styles.formContainer}>
      <Form
        noValidate
        className="form padded-step-container"
        onSubmit={handleSubmit}
        css={styles.form}
      >
        <Container>
          {availablePaymentMethods.length > 0 ? (
            <Form.Group>
              <Form.Label className="medium-text">
                {t("paymentMethods.methods.choose")}
              </Form.Label>
              <ul css={styles.paymentMethods}>
                {availablePaymentMethods.map(type => (
                  <li key={type}>
                    <div css={styles.paymentMethodItem}>
                      <Form.Check
                        custom
                        type="radio"
                        id={type}
                        name="paymentMethod"
                        label={t(`paymentMethods.methods.${type}`)}
                        checked={values.paymentMethod === type}
                        onChange={() => {
                          setFieldTouched("paymentMethod")
                          setFieldValue("paymentMethod", type)
                        }}
                        isInvalid={touched.paymentMethod && !!errors.paymentMethod}
                      />

                      {type === "offline" && values.paymentMethod === "offline" && (
                        <span className="note">{t('paymentMethods.methods.offlineNote')}</span>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </Form.Group>
          ) : (
            <div className="text-center">
              {t("paymentMethods.methods.notAvailable")}
            </div>
          )}
        </Container>
      </Form>
      <SummaryTotal/>
    </div>
  );
}
