import React, { useCallback, useEffect } from "react";
import { css } from "@emotion/core";
import { useSelector } from "react-redux";
import { Container, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import cssVars from "styles/variables.module.scss";
import selectors from "lib/redux/selectors";
import { triggerEvent } from "lib/analytics";
import { addYears } from "lib/helpers/calendar";
import { stringPriceToFloat, formatPrice } from "lib/helpers/utils";
import FormikEffect from "components/formik-effect";
import { useTranslation } from "react-i18next";
import InputIncrementDecrement from "components/elements/input-increment-decrement";
import PrizeSelector from "components/elements/prize-selector";
import { getVoucherPrice } from "lib/helpers/voucher-utils";
import withErrorModal from "lib/hocs/with-error-modal";
import Footer from "../footer";
import {validateNIF} from "../../lib/helpers/vat"
import i18n from "i18next"

// For vouchers, the puchasing limit is always 1 to 10.
const VOUCHER_PURCHASE_MIN = 1;
const VOUCHER_PURCHASE_MAX = 10;

const styles = {
  numVouchersRow: css({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: 0,
    label: {
      margin: "0 0.3em 1rem 0",
      flex: 1,
      whiteSpace: "nowrap",
    },
    "& > div": {
      marginBottom: "1rem",
    },
  }),
  numVouchersGroup: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: "bold",
    "& > span": {
      marginLeft: "0.3em",
    },
    ".base-price": {
      whiteSpace: "nowrap",
    },
    ".extra-discount": {
      color: cssVars.primary,
    },
  }),
  checkBox: css({
    label: {
      fontWeight: "bold",
    },
  }),
};

const schema = yup.object({
  numberOfPeople: yup
    .number()
    .integer()
    .min(VOUCHER_PURCHASE_MIN)
    .max(VOUCHER_PURCHASE_MAX)
    .required(),
  needVatInvoice: yup.boolean().required(),
  name: yup.string().when("needVatInvoice", {
    is: true,
    then: (schema) => schema.min(1).required(),
    otherwise: (schema) => schema.nullable(),
  }),
  vatId: yup.string().when("needVatInvoice", {
    is: true,
    then: (schema) => schema
      .test('validator-custom-name', (value, { createError, path }) => {
        if (!value || (!!value && value.length > 0 && validateNIF(value))) {
          return true
        }

        return createError({
          path,
          message: i18n.t('forms.errors.vatIdInvalid'),
        })
      }),
    otherwise: (schema) => schema.nullable(),
  }),
});

/**
 * Flow Step 0 for Campaign reservations
 */
function BuyVoucher({
  numberOfPeople,
  needVatInvoice,
  name,
  vatId,
  onFormikProps,
  onFormStateChange,
  onNextStep,
  prizeId,
  showModal,
}) {
  const { t } = useTranslation();
  const { currency, offerPrice } = useSelector(selectors.flow.selectedPriceInfo);
  const {
    value: giftVoucherExtraDiscount,
    percentage: giftVoucherExtraDiscountPercentage,
  } = useSelector(selectors.campaign.giftVoucherExtraDiscount);
  const {
    value: premiumVoucherExtraDiscount,
    percentage: premiumVoucherExtraDiscountPercentage,
  } = useSelector(selectors.campaign.premiumVoucherExtraDiscount);
  const voucherExpirationDate = addYears(new Date(), 1);
  const [offerPriceF] = stringPriceToFloat(offerPrice);
  const preSelectedNumberPeople = numberOfPeople || VOUCHER_PURCHASE_MIN;
  const prizes = useSelector(selectors.user.prizes);
  const isTransported = useSelector(selectors.flow.isTransported);
  const selectedPrize = prizes.find(p => p.id === prizeId);
  const isPremiumReservation = useSelector(selectors.flow.isPremiumReservation);

  // discount
  const discount = isPremiumReservation ? premiumVoucherExtraDiscount : giftVoucherExtraDiscount
  const { canUsePrize, finalDiscount, finalPrice } = getVoucherPrice(offerPriceF, discount, selectedPrize)

  const handleValidSubmit = (values) => {
    onFormStateChange(values);
    triggerEvent("Purchase", "buy_as_gift_click_go_to_confirmation_page");
    onNextStep();
    return false;
  };

  useEffect(function init() {
    triggerEvent("Purchase", "buy_as_gift_open_page");
  }, []);

  const handleFormChange = ({ nextValues }) => {
    onFormStateChange(nextValues);
  };

  const handlePrizeChange = useCallback(
    (prizeId) => {
      onFormStateChange({ prizeId })
    },
    [onFormStateChange]
  );

  useEffect(
    function updateInitialStepState() {
      // Trigger a flow step state change because the preselected number of
      // people might never be changed by the user and would thus be null
      onFormStateChange({ numberOfPeople: preSelectedNumberPeople });
    },
    [preSelectedNumberPeople]
  );

  return (
    <div>
      {!isTransported && (
        <PrizeSelector
          prizeId={prizeId}
          onPrizeIdChange={handlePrizeChange}
          title={t("reservation.usePrizesVoucherTitle")}
        />
      )}
      <Container>
        <Formik
          validationSchema={schema}
          onSubmit={handleValidSubmit}
          initialValues={{
            numberOfPeople: preSelectedNumberPeople,
            needVatInvoice,
            name,
            vatId,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            submitForm,
            isSubmitting,
            values,
            touched,
            isValid,
            errors,
          }) => {
            onFormikProps({
              submitForm: () => {
                if (canUsePrize) {
                  submitForm()
                } else {
                  showModal(t("forms.errors.prizeValueTooHighforVoucher"));
                }
              },
              isValid,
              isSubmitting,
              submitLabel: t("flow.continue"),
            });
            return (
              <>
                <FormikEffect onChange={handleFormChange} />
                <Form
                  noValidate
                  className="form padded-step-container"
                  onSubmit={handleSubmit}
                >
                  <Form.Group css={styles.numVouchersRow}>
                    <Form.Label className="medium-text">
                      {t("reservation.numberOfVouchers")}
                    </Form.Label>
                    <div css={styles.numVouchersGroup}>
                      <div>
                        <InputIncrementDecrement
                          noValidate
                          name="numberOfPeople"
                          min={VOUCHER_PURCHASE_MIN}
                          max={VOUCHER_PURCHASE_MAX}
                          defaultValue={preSelectedNumberPeople}
                          onValueChange={(value) => {
                            setFieldTouched("numberOfPeople");
                            setFieldValue("numberOfPeople", value);
                          }}
                          value={values.numberOfPeople}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.numberOfPeople && !!errors.numberOfPeople
                          }
                        />
                      </div>
                      <span>x</span>
                      <span className="base-price">
                        {formatPrice(offerPriceF, currency)}
                      </span>
                      {isPremiumReservation ? (
                        <>
                          {!!premiumVoucherExtraDiscount && (
                            <span className="extra-discount">
                              (-{premiumVoucherExtraDiscountPercentage}%)
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          {!!giftVoucherExtraDiscount && (
                            <span className="extra-discount">
                              (-{giftVoucherExtraDiscountPercentage}%)
                            </span>
                          )}
                        </>
                      )}
                      {!!prizeId && (
                        <span className="extra-discount">
                          (-{selectedPrize.prizeValue}€)
                        </span>
                      )}
                      <span>=</span>
                      <span>
                        {formatPrice(
                          values.numberOfPeople * finalPrice,
                          currency,
                          {
                            minFractionDigits: 2,
                          }
                        )}
                      </span>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <div className="small-text">
                      {t("reservation.voucherExpiresAt", {
                        day: voucherExpirationDate.getDate(),
                        month: t(
                          `calendar.months.${voucherExpirationDate.getMonth()}`
                        ),
                        year: voucherExpirationDate.getFullYear(),
                      })}
                    </div>
                  </Form.Group>
                  <Form.Group css={styles.checkBox}>
                    <Form.Check
                      custom
                      type="checkbox"
                      id="needVatInvoice"
                      name="needVatInvoice"
                      label={t("reservation.needVatInvoice")}
                      checked={values.needVatInvoice}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  {values.needVatInvoice && (
                    <>
                      <Form.Group>
                        <Form.Control
                          className="rounded-underline"
                          type="text"
                          name="name"
                          placeholder={t("forms.name")}
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.name && !!errors.name}
                        />
                        {touched.name && (
                          <Form.Control.Feedback type="invalid">
                            {t("forms.errors.nameInvalid")}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          className="rounded-underline"
                          type="text"
                          name="vatId"
                          placeholder={t("forms.vatId")}
                          value={values.vatId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.vatId && !!errors.vatId}
                        />
                        {touched.vatId && (
                          <Form.Control.Feedback type="invalid">
                            {t("forms.errors.vatIdInvalid")}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </>
                  )}
                  <div className="medium-text">
                    {t("reservation.voucherDeliveryInfo")}
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>

        <div className="padded-step-container">
          <Footer />
        </div>
      </Container>
    </div>
  );
}

export default withErrorModal(BuyVoucher);
