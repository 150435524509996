import api from "lib/api"

const DELIVERY_RATES_FETCH = "DELIVERY_RATES_FETCH"

const fetchDeliveryRates = ({ countryId, postalCode, pickupPoint }) => (dispatch, getState) => {
  const state = getState()

  return dispatch({
    type: DELIVERY_RATES_FETCH,
    payload: api.deliveries.getDeliveryMethodRates({
      language: state.context.language,
      entityId: state.entity.entityId,
      token: state.user.token,
      countryId,
      postalCode,
      pickupPoint,
    }),
  })
}

fetchDeliveryRates.action = DELIVERY_RATES_FETCH

export default fetchDeliveryRates
