import React, { useEffect, useMemo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container, Form } from "react-bootstrap"
import { css } from "@emotion/core"
import { Formik } from "formik"
import * as yup from "yup"
import selectors from "lib/redux/selectors"
import { triggerEvent, trackFbEvent } from "lib/analytics"
import withErrorModal from "lib/hocs/with-error-modal"
import { useTranslation } from "react-i18next"
import actions from "lib/redux/actions"
import {
  dateTimesObjectToOrderedArray,
  getBookingStartTimeAsap,
  createJavaDate,
  getJavaDateOffset,
} from "lib/helpers/calendar"
import { TransportInstants } from "lib/helpers/constants"
import phoneCodes from "lib/helpers/phone-codes"
import FormikEffect from "components/formik-effect"
import ReservationSummaryHeading from "components/elements/reservation-summary-heading"
import { discardShoppingCart } from "lib/helpers/inter-frame-communication"
import cssVars from "styles/variables.module.scss"
import { mq } from "styles"
import Footer from "../footer"
import * as Sentry from "@sentry/nextjs"

const styles = {
  contactText: css(
    mq({
      fontSize: ["1rem", "1.25rem"],
      fontWeight: 500,
      color: cssVars.gray800,
      textAlign: "center",
    })
  ),
  phoneRow: css({
    display: "flex",
    select: {
      minWidth: "10em",
    },
    ".phone-number": {
      flex: 1,
      marginLeft: "1rem",
    },
  }),
}

/**
 * Flow Step 3 - Customer name, phone notes and actual booking submission
 */
function BookingConfirmation({
  stepState,
  updateStepState,
  showModal,
  onFormikProps,
  onNextStep,
  onPrevStep,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const loggedIn = useSelector(selectors.user.loggedIn)
  const context = useSelector((state) => state.context)
  const flow = useSelector((state) => state.flow.steps)
  const isTransported = useSelector(selectors.flow.isTransported)
  const shoppingCart = useSelector(selectors.shoppingCart.shoppingCart)
  const { shoppingCartFinalPrice } = useSelector(selectors.flow.selectedPriceInfo)
  const serviceId = useSelector(selectors.flow.serviceId)
  const merchantNotes = useSelector(selectors.flow.bookingMerchantNotes)
  const {
    campaignId,
    bookingType,
    minMinutesBeforeTimeSelection,
    deliveryCost,
    discount,
    points,
  } = useSelector(selectors.product.info)

  let schema = yup.object({
    name: yup.string().trim().required(),
    prefix: yup.string(),
    phone: yup
      .string()
      .trim()
      .when("prefix", {
        is: "+351",
        then: (schema) => schema.matches(/^9[0-9]{8}$/),
        otherwise: (schema) => schema.matches(/[0-9]+/),
      })
      .required(),
    email: !loggedIn && context.allowGuestLogin
      ? yup.string().email().required()
      : yup.string().email(),
  })

  const showBookingFinishingNote = !!merchantNotes && !!merchantNotes.bookingFinishingNote

  useEffect(function init() {
    triggerEvent(
      "Purchase",
      "Open_final_step_to_choose_phone_and_confirm_booking"
    )
  }, [])

  const handleValidSubmit = async () => {
    // Perform the actual reservation/booking HTTP request

    const {
      transportType,
      shoppingCartNotes,
      street,
      floorDoor,
      city,
      book,
      name,
      email,
      prefix,
      phone,
      notes,
      vatId,
    } = flow
    const { prizeId, numberOfPeople, date, dateTimes, transportInstant } = book

    const dateTimesArray = dateTimesObjectToOrderedArray(dateTimes)
    const firstDateTime = dateTimesArray[0]

    let dateInfo = {}
    if (bookingType === 6) {
      // Campaign/Booking type 6 allows for multiple reservation dates
      dateInfo = dateTimesArray.map((dateTime, index) => ({
        startTime: createJavaDate(dateTime.date, dateTime.time),
        durationSec: 0, // TODO
      }))
    } else if (bookingType === 3) {
      // Single day choice and no time choice
      dateInfo = {
        startTime: `${date}T00:00:00${getJavaDateOffset()}`,
        durationSec: 0,
      }
    } else if (bookingType === 5) {
      // No day and no time choice
      dateInfo = {}
    } else if (
      bookingType === 7 &&
      transportInstant === TransportInstants.ASAP
    ) {
      dateInfo = {
        startTime: getBookingStartTimeAsap(minMinutesBeforeTimeSelection),
        durationSec: 0,
      }
    } else {
      // Single day and time choice
      dateInfo = {
        startTime: createJavaDate(firstDateTime.date, firstDateTime.time),
        durationSec: 0,
      }
    }

    const nameParts = name.split(/\s+/)
    const userInformation = {
      // first name part
      givenName: nameParts.shift(),
      // all remaining name parts
      familyName: nameParts.join(" "),
      email: loggedIn ? user.username : email,
      language: user.notificationsLanguage,
      partnerUserId: "", // TODO
      mobileNumber: {
        dialPrefix: String(prefix).replace(/[-\s]/g, ""),
        number: String(phone).replace(/\s/g, ""),
      },
    }

    const taxInformation = {
      fiscalName: name,
      fiscalNumber: vatId,
    }

    const orderInformation = isTransported
      ? {
          deliveryDetail: {
            transportType: transportType.toUpperCase(),
            street,
            floorDoor,
            city,
            postalCode: "",
          },
          orderItems: shoppingCart.map((item) => {
            const priceWithDiscount =
              item.price -
              (discount ? item.price * (Math.abs(discount) / 100) : 0)

            return {
              itemId: item.id,
              name: item.name,
              quantity: 1,
              // Price must include discount
              price: parseFloat(priceWithDiscount.toFixed(2)),
            }
          }),
          deliveryCost,
          orderAmount: shoppingCartFinalPrice,
          notes: [shoppingCartNotes, notes].filter(Boolean).join("\n"),
        }
      : {
          notes,
        }

    const productBaseInfo = serviceId
      ? {
          campaignId: null,
          shopMerchantServiceIds: [serviceId],
        }
      : {
          campaignId,
          shopMerchantServiceIds: [],
        }

    const bookingPayload = {
      ...productBaseInfo,
      prizeTransactionId: prizeId,
      resourceIds: [0], // TODO
      slots: Array.isArray(dateInfo) ? dateInfo : [dateInfo],
      partySize: isTransported ? 1 : numberOfPeople,
      refererLink: document.referrer,
      userInformation,
      taxInformation,
      orderInformation,
      onBehalfOfUser: true, // TODO
    }

    try {
      triggerEvent("Purchase", "submit_booking_form")

      const response = await dispatch(
        actions.product.book({
          // In this special scenario we send the clientPlatform's value (WEB,
          // Android or ios) in the clientApplication in order to inform the
          // back-end if the booking was made using the mobile apps or the web app.
          clientApplication: context.clientPlatform || context.clientApplication,
          providerId: context.providerId,
          channelManager: context.channelManager,
          payload: bookingPayload,
        })
      )

      await dispatch(actions.product.rwgConversion())

      const metric4 =
        dateInfo && dateInfo.dateScheduled
          ? new Date(dateInfo.dateScheduled) - new Date()
          : null
      const { valueCharged, transactionId } = response.action.payload.data
      triggerEvent("Purchase", "successful_booking", null, {
        eventValue: parseInt(valueCharged),
        dimension10: transactionId,
        metric1: valueCharged,
        metric2: numberOfPeople,
        ...(metric4 && { metric4 }),
        metric6: points,
      })

      //FIXME: temp analytics v4 - improve
      if (serviceId) {
        window.gtag("event", "purchase", {
          transaction_id: transactionId,
          value: valueCharged,
          currency: "EUR",
          items: [
            {
              item_id: `service_${serviceId}`,
            },
          ]
        })
      } else {
        window.gtag("event", "purchase", {
          transaction_id: transactionId,
          value: valueCharged,
          currency: "EUR",
          items: [
            {
              item_id: `campaign_${campaignId}`,
            },
          ]
        })
      }

      trackFbEvent("Purchase", {
        currency: "EUR",
        value: valueCharged,
        num_items: numberOfPeople,
        order_id: transactionId,
      })

      discardShoppingCart()

      onNextStep()
    } catch (err) {
      Sentry.captureException(err);
      triggerEvent("Purchase", "error_after_submit_form", err.message)
      showModal(err.message)
    }

    return false
  }

  const handleFormChange = useCallback(
    ({ nextValues }) => {
      updateStepState(nextValues)
    },
    [updateStepState]
  )

  return (
    <div>
      <ReservationSummaryHeading onClick={onPrevStep} />
      <Container>
        <Formik
          validateOnMount
          validationSchema={schema}
          onSubmit={handleValidSubmit}
          initialValues={{
            name: stepState.name,
            prefix: stepState.prefix,
            email: stepState.email || "",
            phone: stepState.phone || "",
            notes: stepState.notes || "",
            vatId: stepState.vatId || "",
          }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            submitForm,
            values,
            touched,
            errors,
            isValid,
            isSubmitting,
          }) => {
            onFormikProps({
              submitForm,
              isValid,
              isSubmitting,
              submitLabel: t(isTransported ? "flow.order" : "flow.book"),
            })
            return (
              <>
                <FormikEffect onChange={handleFormChange} />
                <Form
                  noValidate
                  className="form padded-step-container"
                  onSubmit={handleSubmit}
                >
                  <Form.Group>
                    <div css={styles.contactText}>
                      {t("reservation.contactInfoRequirement")}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      className="rounded-underline"
                      type="text"
                      name="name"
                      placeholder={t("forms.name")}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.name && !!errors.name}
                    />
                    {touched.name && (
                      <Form.Control.Feedback type="invalid">
                        {t("forms.errors.nameInvalid")}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  {!loggedIn && context.allowGuestLogin && (
                    <Form.Group>
                      <Form.Control
                        className="rounded-underline"
                        type="text"
                        name="email"
                        placeholder={t("forms.email")}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.email && !!errors.email}
                      />
                      {touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {t("forms.errors.emailInvalid")}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  )}
                  <Form.Group css={styles.phoneRow}>
                    <div className="phone-prefix">
                      <Form.Control
                        as="select"
                        className="rounded-underline"
                        name="prefix"
                        placeholder={t("forms.phonePrefix")}
                        defaultValue={values.prefix}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.prefix && !!errors.prefix}
                      >
                        {phoneCodes.map((entry) => (
                          <option
                            key={`${entry.code}-${entry.dial_code}`}
                            value={entry.dial_code}
                          >
                            {entry.code} ({entry.dial_code})
                          </option>
                        ))}
                      </Form.Control>
                      {touched.prefix && (
                        <Form.Control.Feedback type="invalid">
                          {t("forms.errors.phonePrefixInvalid")}
                        </Form.Control.Feedback>
                      )}
                    </div>
                    <div className="phone-number">
                      <Form.Control
                        className="rounded-underline"
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        name="phone"
                        placeholder={t("forms.phone")}
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.phone && !!errors.phone}
                      />
                      {touched.phone && (
                        <Form.Control.Feedback type="invalid">
                          {t("forms.errors.phoneInvalid")}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Form.Group>
                  {bookingType <= 6 ? (
                    <Form.Group>
                      <Form.Control
                        as="textarea"
                        rows="2"
                        name="notes"
                        placeholder={t("forms.notesForMerchant")}
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group>
                      <Form.Control
                        className="rounded-underline"
                        type="text"
                        name="vatId"
                        placeholder={t("forms.vatId")}
                        value={values.vatId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.vatId && !!errors.vatId}
                      />
                      {touched.vatId && (
                        <Form.Control.Feedback type="invalid">
                          {t("forms.errors.vatIdInvalid")}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  )}
                  {showBookingFinishingNote && (
                    <div>
                      <h5>{t("reservation.merchant_additional_info")}</h5>
                      <p className="merchant-notes">{merchantNotes.bookingFinishingNote}</p>
                    </div>
                  )}
                </Form>
              </>
            )
          }}
        </Formik>

        <div className="padded-step-container">
          <Footer />
        </div>
      </Container>
    </div>
  )
}

export default withErrorModal(BookingConfirmation)
