// Helper functions to communicate with the parent window (since we're an
// iframe). Allows us to share the user that logged in with the parent window,
// for instance.

import { guid, promiseTimeout } from "./utils";

let hasValidParentWindow = false;
let promiseChain = Promise.resolve();
const promises = {};

const sendMessage = ({ action, data, timeout, throwIfNoParent }) => {
  const shouldThrow = throwIfNoParent && !hasValidParentWindow;
  if (shouldThrow) {
    const err = new Error("No parent window");
    err.status = 501;
    return Promise.reject(err);
  }

  if (
    typeof window === "undefined" ||
    !window.parent ||
    window === window.parent
  ) {
    // Not running inside an iframe, no parent to communicate with
    return;
  }

  const msgObj = {
    msgId: guid(),
    action,
    data,
  };
  const msg = JSON.stringify(msgObj);

  const prom = new Promise((resolve, reject) => {
    promiseChain = promiseChain
      .then(function () {
        return new Promise((chainResolve, chainReject) => {
          promises[msgObj.msgId] = {
            chainResolve: chainResolve,
            chainReject: chainReject,
            promiseResolve: resolve,
            promiseReject: reject,
          };
          window.parent.postMessage(msg, "*");
        });
      })
      .catch((err) => {
        if (promises[msgObj.msgId]) {
          delete promises[msgObj.msgId];
        }
        reject(err);
      });
  });

  return timeout ? promiseTimeout(prom, timeout) : prom;
};

const handleParentMessage = (e) => {
  let msg;
  try {
    msg = JSON.parse(e.data);
  } catch (err) {
    return;
  }

  if (promises[msg.msgId]) {
    if (msg.chainNext) {
      // Resolve chain promise to send next msg, if available
      promises[msg.msgId].chainResolve();
    } else if (msg.resolve) {
      promises[msg.msgId].promiseResolve.apply(null, [msg.args]);
      delete promises[msg.msgId];
    } else if (msg.reject) {
      const args = msg.args || {};
      let err;
      if (args.message) {
        err = new Error(args.message);
        Object.keys(args).forEach(function (key) {
          err[key] = args[key];
        });
      } else {
        err = args;
      }
      promises[msg.msgId].promiseReject.apply(null, [err]);
      delete promises[msg.msgId];
    }
  }
};

if (typeof window !== "undefined") {
  window.addEventListener("message", handleParentMessage);
}

const checkValidParentWindow = async () => {
  try {
    const pong = await sendMessage({
      action: "ping",
    });

    if (pong === true) {
      hasValidParentWindow = true;
    }
  } catch (err) {
    if (err && err.status === 501) {
      // Parent doesn't support pong but it supports inter-frame communication
      // since we received an expected error
      hasValidParentWindow = true;
    }
  }
};

checkValidParentWindow();

export const setLoggedInUser = ({ id, token }) => {
  return sendMessage({
    action: "setLoggedInUser",
    data: {
      id,
      token,
    },
  });
};

/**
 * Share the reservation details with the parent frame
 *
 * @param {} reservation
 */
export const setReservationInfo = (reservation) => {
  return sendMessage({
    action: "setReservationInfo",
    data: {
      ...reservation,
    },
  });
};

export const tryGetLocationCoordinates = () => {
  return sendMessage({
    action: "tryGetLocationCoordinates",
    throwIfNoParent: true,
  })
}

export const discardShoppingCart = () => {
  return sendMessage({
    action: "discardShoppingCart",
  });
};

export const removeShoppingCartItem = (itemId) => {
  return sendMessage({
    action: "removeShoppingCartItem",
    throwIfNoParent: true,
    data: {
      itemId,
    },
  });
};

export const checkNativeFacebookLogin = () => {
  return sendMessage({
    action: "checkFacebookPlugin",
    throwIfNoParent: true,
  });
};

export const tryNativeFacebookLogin = () => {
  return sendMessage({
    action: "loginWithFacebook",
    throwIfNoParent: true,
  });
};

export const tryNativeGoogleLogin = () => {
  return sendMessage({
    action: "loginWithGoogle",
    throwIfNoParent: true,
  });
};

export const checkNativeGoogleLogin = () => {
  return sendMessage({
    action: "checkGooglePlugin",
    throwIfNoParent: true,
  });
};

export const tryNativeAppleLogin = () => {
  return sendMessage({
    action: "loginWithApple",
    throwIfNoParent: true,
  });
};

export const checkNativeAppleLogin = () => {
  return sendMessage({
    action: "checkApplePlugin",
    throwIfNoParent: true,
  });
};

export const isAppleLoginAvailable = () => {
  return sendMessage({
    action: 'applePluginAvailable',
    throwIfNoParent: true,
  })
}

export const navigateTo = (url) => {
  return sendMessage({
    action: "navigateTo",
    data: {
      url,
    },
    throwIfNoParent: true,
  });
};

export const openUrl = (url) => {
  return sendMessage({
    action: "openUrl",
    data: {
      url,
    },
    throwIfNoParent: true,
  })
}
