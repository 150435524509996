import React, {useCallback, useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useTranslation} from "react-i18next"
import selectors from "lib/redux/selectors"
import actions from "../../lib/redux/actions"
import {Container, Form} from "react-bootstrap"
import ShoppingCartList from "../shopping-cart/list"
import {DeliveryTypes} from "../../lib/helpers/constants"
import {css} from "@emotion/core"
import {mq} from "../../styles"
import cssVars from "../../styles/variables.module.scss"
import withErrorModal from "../../lib/hocs/with-error-modal"
import {discardShoppingCart, openUrl} from "../../lib/helpers/inter-frame-communication"
import SummaryTotal from "../elements/summary-total"
import {useFormik} from "formik"
import useFormikEffect from "../useFormikEffect"
import * as yup from "yup"
import phoneCodes from "../../lib/helpers/phone-codes.json"
import AddressSelection, {addressSchema} from "./address-selection"
import {IconWrapper} from "../icons"

const styles = {
  formContainer: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }),
  form: css({
    overflowY: 'auto',
    flexGrow: 1,
    ".price": {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
  }),
  phoneRow: css({
    display: "flex",
    columnGap: "1rem",
    alignItems: "center",
    select: {
      minWidth: "10em",
    },
    ".phone-number": {
      flex: 1,
    },
  }),
  nameRow: css({
    '.input': {
      display: 'flex',
      alignItems: 'center',
      columnGap: '1rem',
    },
  }),
  deliveryDetails: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  }),
  transportTypes: css({
    listStyle: "none",
    padding: 0,
    margin: 0,
    ".item-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: "2.5rem",
    },
    ".custom-control": {
      display: "inline-block",
      marginRight: "1rem",
    },
    ".badge": {
      fontSize: "0.9rem",
    },
    ".prices": {
      textAlign: "right",
      // fontSize: "1.6rem",
      // fontWeight: 500,
    },
  }),
  contactText: css(
    mq({
      fontSize: ["1rem", "1.25rem"],
      fontWeight: 500,
      color: cssVars.gray800,
      textAlign: "center",
    }),
  ),
  deliveryType: css({
    fontSize: '1rem',
    fontWeight: 400,
  }),
  deliveryAddress: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    ".name": {
      marginBottom: 0,
      fontSize: '1rem',
      fontWeight: 400,
    },
    ".address": {
      marginBottom: 0,
    },
  }),
  addressLineRow: css({
    display: "flex",
    columnGap: "16px",
    ".street": {
      flex: "65%",
    },
    ".floorDoor": {
      flex: "35%",
    },
    ".postalCode": {
      flex: "50%",
    },
    ".city": {
      flex: "50%",
    },
  }),
  total: css({
    ".price-before": {
      textDecoration: "line-through",
      textAlign: "right",
    },
    ".discount": {
      textAlign: "right",
    },
    ".pricing-line": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "0.4rem",
      "&.total": {
        fontWeight: "bold",
        fontSize: "1.2rem",
      },
    },
    ".title": {
      fontSize: "1rem",
    },
    ".value": {
      fontSize: "1rem",
    },
  }),
}

/**
 * Product checkout
 */
function CheckoutConfirmation({stepState, updateStepState, showModal, onFormikProps}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const loggedIn = useSelector(selectors.user.loggedIn)
  const context = useSelector((state) => state.context)
  const [isLoadingPayment, setIsLoadingPayment] = useState(false)
  const [includeFiscalInformation, setIncludeFiscalInformation] = useState(false)
  const [fiscalAddressValid, setFiscalAddressValid] = useState(null)

  const formRef = useRef()
  const fiscalInformationRef = useRef()

  const schema = yup.object({
    name: yup.string().trim().required(),
    prefix: yup.string(),
    phone: yup
      .string()
      .trim()
      .when("prefix", {
        is: "+351",
        then: (schema) => schema.matches(/^9[0-9]{8}$/),
        otherwise: (schema) => schema.matches(/[0-9]+/),
      })
      .required(),
    email: !loggedIn && context.allowGuestLogin
      ? yup.string().email().required()
      : yup.string().email(),
    fiscalAddressId: includeFiscalInformation ? yup.number().required() : yup.number().nullable(),
  })

  //TODO: vat validation

  useEffect(() => {
    if (includeFiscalInformation) {
      formRef.current.scrollTo({
        top: fiscalInformationRef.current.offsetTop,
        behavior: 'smooth',
      })
    }
  }, [includeFiscalInformation])

  const {
    deliveryType,
    paymentMethod,
    deliveryAddressId,
    fiscalAddressId,
    pickupPoint,
    shoppingCartNotes,
    addressName,
    name,
    email,
    prefix,
    phone,
    notes,
    vatId,
    shopId,
    countryId,
    postalCode,
    street,
    city,
    floorDoor,
    productOrder,
  } = stepState

  const addresses = useSelector(selectors.addresses.addresses)
  const selectedDeliveryAddress = addresses.find(address => address.id === deliveryAddressId)

  const isDelivery = deliveryType === DeliveryTypes.DELIVERY;
  const isPickupPoint = deliveryType === DeliveryTypes.PICKUP_POINT

  const complete = useCallback(async () => {
    setIsLoadingPayment(true)

    const orderInformation = {
      delivery: {
        deliveryMethods: Object.keys(productOrder).reduce((acc, shopId) => {
          acc.push({
            shopId,
            shopDeliveryMethodId: productOrder[shopId].shopDeliveryMethodId,
            pickupPoint: pickupPoint,
          })
          return acc
        }, []),
      },
      shopNotes: Object.keys(productOrder).reduce((acc, shopId) => {
        acc.push({
          shopId,
          notes: productOrder[shopId].notes,
        })
        return acc
      }, []),
      notes: [shoppingCartNotes, notes].filter(Boolean).join("\n"),
    }

    if (includeFiscalInformation) {
      orderInformation.fiscalAddressId = fiscalAddressId
    }

    orderInformation.paymentMethod = paymentMethod

    if (selectedDeliveryAddress) {
      orderInformation.delivery.addressId = deliveryAddressId
    } else if (pickupPoint) {
      orderInformation.delivery = {
        ...orderInformation.delivery,
        addressName,
        countryId,
        street,
        postalCode,
        floorDoor,
        city,
      }
    }

    const nameParts = name.split(/\s+/)
    const userInformation = {
      // first name part
      givenName: nameParts.shift(),
      // all remaining name parts
      familyName: nameParts.join(" "),
      email: loggedIn ? user.username : email,
      mobileNumber: {
        dialPrefix: String(prefix).replace(/[-\s]/g, ""),
        number: String(phone).replace(/\s/g, ""),
      },
    }

    const taxInformation = {
      fiscalName: name,
      fiscalNumber: vatId,
    }

    const payload = {
      refererLink: document.referrer,
      userInformation,
      taxInformation,
      orderInformation,
    }

    try {
      const result = await dispatch(actions.orders.order({ payload }))

      if (result.value.data) {
        try {
          await discardShoppingCart()
          await openUrl(result.value.data.paymentUrl)
        } catch {
          window.open(result.value.data.paymentUrl, '_self').focus()
        }
      }

    } catch (err) {
      setIsLoadingPayment(false)
      showModal(err.message)
      return false
    }
  })

  const handleFormChange = useCallback(
    ({ nextValues }) => {
      updateStepState(nextValues);
    },
    [updateStepState]
  );

  const {
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    values,
    touched,
    errors,
    isValid,
  } = useFormik({
    validationSchema: schema,
    onSubmit: complete,
    initialValues: {
      name: stepState.name,
      prefix: stepState.prefix,
      email: stepState.email,
      phone: stepState.phone,
      notes: stepState.notes,
    }
  })

  useFormikEffect(handleFormChange, { values })

  onFormikProps({
    submitForm: complete,
    submitLabel: paymentMethod === 'online' ? t("flow.pay") : t('flow.confirm'),
    isValid,
    waitingOverlay: isLoadingPayment,
    isSubmitting: isLoadingPayment,
    waitingOverlayText: t('paymentMethods.loadingPayment'),
  })

  return (
    <div css={styles.formContainer}>
      <Form
        ref={formRef}
        noValidate
        className="form padded-step-container"
        css={styles.form}
      >
        <Container>
          <Form.Group>
            <Form.Label className="medium-text">
              {t("reservation.confirmCartTitle")}
            </Form.Label>
            <div>
              <ShoppingCartList editMode={false}/>
            </div>
          </Form.Group>

          {shoppingCartNotes && (
            <Form.Group>
              <Form.Label className="medium-text">
                {t("forms.notesForMerchant")}
              </Form.Label>
              <div>
                {shoppingCartNotes}
              </div>
            </Form.Group>
          )}

          <div css={styles.deliveryDetails}>
            <Form.Group>
              <Form.Label className="medium-text">
                {t("reservation.deliveryType")}
              </Form.Label>
              <div css={styles.deliveryType}>{t(`delivery.type.${deliveryType}`)}</div>
            </Form.Group>

            {(isDelivery || isPickupPoint) && (
              <Form.Group>
                <Form.Label className="medium-text">
                  {isDelivery ? t("reservation.deliveryAddress") : t("reservation.pickupPoint")}
                </Form.Label>

                <div css={styles.deliveryAddress}>
                  {selectedDeliveryAddress && (
                    <>
                      <span className="name">{selectedDeliveryAddress.addressName}</span>
                      <span className="address">
                        {selectedDeliveryAddress.street} {selectedDeliveryAddress.floorDoor} {selectedDeliveryAddress.postalCode} {selectedDeliveryAddress.city}
                      </span>
                    </>
                  )}

                  {isPickupPoint && pickupPoint && (
                    <>
                      {addressName && (
                        <span className="name">{addressName}</span>
                      )}
                      <span className="address">{street} {floorDoor} {postalCode} {city}</span>
                    </>
                  )}
                </div>
              </Form.Group>
            )}

            <Form.Group>
              <Form.Label className="medium-text">
                {t("reservation.paymentMethod")}
              </Form.Label>
              <div css={styles.deliveryType}>{t(`paymentMethods.methods.${paymentMethod}`)}</div>
            </Form.Group>
          </div>

          <Form.Group css={styles.nameRow}>
            <Form.Label className="medium-text">
              {t("reservation.clientInformation")}
            </Form.Label>
            <div className="input">
              <IconWrapper
                name="mdiAccountOutline"
                size="1.8rem"
              />
              <Form.Control
                className="rounded-underline"
                type="text"
                name="name"
                placeholder={t("forms.name")}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.name && !!errors.name}
              />
            </div>
            {touched.name && (
              <Form.Control.Feedback type="invalid">
                {t("forms.errors.nameInvalid")}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {!loggedIn && context.allowGuestLogin && (
            <Form.Group>
              <Form.Control
                className="rounded-underline"
                type="text"
                name="email"
                placeholder={t("forms.email")}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && !!errors.email}
              />
              {touched.email && (
                <Form.Control.Feedback type="invalid">
                  {t("forms.errors.emailInvalid")}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          )}
          <Form.Group css={styles.phoneRow}>
            <IconWrapper
              name="mdiPhoneOutline"
              size="1.8rem"
            />
            <div className="phone-prefix">
              <Form.Control
                as="select"
                className="rounded-underline"
                name="prefix"
                placeholder={t("forms.phonePrefix")}
                defaultValue={values.prefix}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.prefix && !!errors.prefix}
              >
                {phoneCodes.map((entry) => (
                  <option
                    key={`${entry.code}-${entry.dial_code}`}
                    value={entry.dial_code}
                  >
                    {entry.code} ({entry.dial_code})
                  </option>
                ))}
              </Form.Control>
              {touched.prefix && (
                <Form.Control.Feedback type="invalid">
                  {t("forms.errors.phonePrefixInvalid")}
                </Form.Control.Feedback>
              )}
            </div>
            <div className="phone-number">
              <Form.Control
                className="rounded-underline"
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                name="phone"
                placeholder={t("forms.phone")}
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.phone && !!errors.phone}
              />
              {touched.phone && (
                <Form.Control.Feedback type="invalid">
                  {t("forms.errors.phoneInvalid")}
                </Form.Control.Feedback>
              )}
            </div>
          </Form.Group>

          <Form.Group css={styles.checkBox}>
            <Form.Check
              custom
              type="checkbox"
              id="includeFiscalInformation"
              name="includeFiscalInformation"
              label={t("reservation.needVatInvoice")}
              checked={includeFiscalInformation}
              onChange={() => setIncludeFiscalInformation(!includeFiscalInformation)}
            />
          </Form.Group>

          {includeFiscalInformation && (
            <div className="mb-5" ref={fiscalInformationRef}>
              <AddressSelection
                value={fiscalAddressId}
                shopId={shopId}
                fiscal
                invalid={fiscalAddressValid === false}
                onChange={async (address) => {
                  await setFieldTouched("fiscalAddressId")
                  if (address) {
                    await setFieldValue("fiscalAddressId", parseInt(address.id))
                  } else {
                    await setFieldValue("fiscalAddressId", null)
                  }

                  try {
                    await addressSchema.validate(address, { strict: true })
                    await setFiscalAddressValid(true)
                  } catch (e) {
                    await setFiscalAddressValid(false)
                  }
                }}
              />
              {fiscalAddressValid === false && (
                <div className="text-danger">
                  {t("forms.errors.addressInvalid")}
                </div>
              )}
            </div>
          )}
        </Container>
      </Form>
      <SummaryTotal />
    </div>
  )
}

export default withErrorModal(CheckoutConfirmation)
