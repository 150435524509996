module.exports = ({ fetch }) => {
  return {
    getDeliveryCountries: ({ language, entityId, token }) =>
      fetch(`/mygon-middleware/rest/v1/web/delivery/countries`, {
        method: "get",
        queryParams: { language, entityId, token },
      }),
    // returns all delivery methods, and the rate for the user cart
    // this allows us to show a starting price for the delivery
    // before the user selects the delivery type and the address
    getDeliveryMethods: ({ language, entityId, token }) =>
      fetch(`/mygon-middleware/rest/v1/web/delivery/rates`, {
        method: "get",
        queryParams: { language, entityId, token },
      }),
    // returns the delivery method rates for the given country and postal code, and the price for the user cart
    getDeliveryMethodRates: ({ language, entityId, countryId, postalCode, pickupPoint, token }) =>
      fetch(`/mygon-middleware/rest/v1/web/delivery/rates`, {
        method: "get",
        queryParams: { language, entityId, countryId, postalCode, pickupPoint, token },
      }),
    getPickupPoints: ({ token, language, entityId, shopDeliveryMethodIds, latitude, longitude }) =>
      fetch(`/mygon-middleware/rest/v1/web/delivery/pickup-points`, {
        method: "get",
        queryParams: { token, language, entityId, shopDeliveryMethodIds, latitude, longitude },
      }),
  };
};
