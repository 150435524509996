import React, {useCallback, useEffect, useRef, useState} from "react"
import { css } from "@emotion/core";
import {useDispatch, useSelector} from "react-redux"
import {Form, Container} from "react-bootstrap"
import {useFormik} from "formik"
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {AllDeliveryTypes, DeliveryTypes} from "lib/helpers/constants"
import selectors from "lib/redux/selectors";
import { formatPrice } from "lib/helpers/utils";
import cssVars from "styles/variables.module.scss";
import { mq } from "styles";
import actions from "../../lib/redux/actions"
import SummaryTotal from "../elements/summary-total"
import useFormikEffect from "../useFormikEffect"
import PickupPointSearch from "./pickup-point-search"
import AddressSelection from "./address-selection"

const styles = {
  formContainer: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }),
  form: css({
    overflowY: 'auto',
    flexGrow: 1,
    ".price": {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
  }),
  selectedDeliveryType: css({
    marginBottom: '1.2rem',
    ".shopDeliveryMethods": {
      marginLeft: '2rem',
      ".shop": {
        borderBottom: `1px solid ${cssVars.gray500}`,
        paddingTop: '0.4rem',
        paddingBottom: '0.4rem',
        ".top": {
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          ".name": {
            fontSize: '1.1rem',
            fontWeight: 500,
          },
        },
        ".unavailableNote": {
          color: cssVars.danger,
          fontSize: '0.8rem',
        },
      },
    },
  }),
  changeDeliveryType: css({
    textDecoration: 'underline',
    cursor: 'pointer',
  }),
  deliveryTypeItem: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "2.5rem",
    ".custom-control-label": {
      fontSize: '1rem',
      fontWeight: 400,
      "&::before, &::after": {
        top: "0",
        bottom: "0",
        margin: "auto 0",
      },
    },
    ".custom-control": {
      display: "inline-block",
      marginRight: "1rem",
    },
    ".prices": {
      textAlign: "right",
      // fontSize: "1.6rem",
      // fontWeight: 500,
      ".startingFrom": {
        fontSize: "0.9rem",
        fontWeight: 300,
        marginRight: '0.3rem',
      }
    },
  }),
  transportTypes: css({
    listStyle: "none",
    padding: 0,
    margin: 0,
    marginBottom: '1rem',
    ".badge": {
      fontSize: "0.9rem",
    },
    ".disabled": {
      opacity: 0.7,
    },
  }),
  shopDeliveryMethodOptions: css({
    borderTop: `1px solid ${cssVars.gray500}`,
    ".options": {
      listStyle: "none",
      padding: 0,
      margin: 0,
      marginBottom: '0.3rem',
      ".option": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: '0.2rem 0',
        ".price": {
          fontSize: "1rem",
          fontWeight: 500,
        }
      }
    }
  }),
  contactText: css(
    mq({
      fontSize: ["1rem", "1.25rem"],
      fontWeight: 500,
      color: cssVars.gray800,
      textAlign: "center",
    })
  ),
  addressLineRow: css({
    display: "flex",
    columnGap: "16px",
    ".country": {
      flex: "100%",
    },
    ".street": {
      flex: "65%",
    },
    ".floorDoor": {
      flex: "35%",
    },
    ".postalCode": {
      flex: "50%",
    },
    ".city": {
      flex: "50%",
    },
  }),
};

const schema = yup.object({
  deliveryType: yup
    .string()
    .oneOf(AllDeliveryTypes)
    .required(),
  //shopDeliveryMethodId: yup.number().required(),
  deliveryAddressId: yup.number().when("deliveryType", {
    is: DeliveryTypes.DELIVERY,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  pickupPoint: yup.string().when("deliveryType", {
    is: DeliveryTypes.PICKUP_POINT,
    then: (schema) => schema.min(1).required(),
    otherwise: (schema) => schema.nullable(),
  }),
  productOrder: yup.object().test("validMap", "Map is invalid", function (val) {
    if (!val) {
      return false
    }

    // if pickup point is set all shops must have it defined
    if (this.parent.deliveryType === DeliveryTypes.PICKUP_POINT) {
      return Object.keys(val).every((key) => !!val[key].pickupPoint && !!val[key].shopDeliveryMethodId);
    }

    return Object.keys(val).every((key) => !!val[key].shopDeliveryMethodId)
  }),
});

export default function FlowStepDeliveryMethod({
                                               stepState,
                                               updateStepState,
                                               onFormikProps,
                                               onNextStep,
                                             }) {
  const { t } = useTranslation();
  const formRef = useRef()
  const deliveryMethodsRef = useRef()

  const {
    deliveryType,
    deliveryAddressId,
    addressName,
    countryId,
    postalCode,
    street,
    city,
    productOrder,
    pickupPoint,
  } = stepState;

  const handleValidSubmit = async () => {
    onNextStep();
    return false;
  }

  const {
    handleSubmit,
    setFieldValue,
    setTouched,
    setFieldTouched,
    submitForm,
    isSubmitting,
    values,
    touched,
    isValid,
    errors,
  } = useFormik({
    initialValues: {
      deliveryAddressId,
      deliveryType,
      addressName,
      countryId,
      postalCode,
      street,
      city,
      productOrder,
    },
    onSubmit: handleValidSubmit,
    validationSchema: schema,
  })

  const [isLoadingRates, setIsLoadingRates] = useState(true)
  const dispatch = useDispatch()

  const deliveryTypes = useSelector(selectors.deliveries.types)

  const shopIds = useSelector(selectors.shoppingCart.shopIds)

  const selectedDeliveryType = deliveryTypes.find(t => t.type === deliveryType)

  const availableDeliveryMethods = useSelector(selectors.deliveries.availableDeliveryMethods)
  const deliveryMethods = useSelector(selectors.deliveries.deliveryMethodRates)
  const selectedDeliveryMethods = useSelector(selectors.deliveries.selectedDeliveryMethods)

  const isDelivery = deliveryType === DeliveryTypes.DELIVERY;
  const isPickupPoint = deliveryType === DeliveryTypes.PICKUP_POINT
  const isPickup = deliveryType === DeliveryTypes.STORE_PICKUP

  const addresses = useSelector(selectors.addresses.addresses)
  const selectedAddress = addresses.find(address => address.id === deliveryAddressId)


  useEffect(() => {
    dispatch(actions.deliveries.fetchDeliveryMethods({}))
  }, [])

  useEffect(() => {
    async function handleChange() {
      // reset delivery method if delivery type changes
      const newProductOrder = {...productOrder}
      for (const shopId of shopIds) {
        if (!newProductOrder[shopId]) {
          newProductOrder[shopId] = {}
        }
        newProductOrder[shopId] = {
          ...newProductOrder[shopId],
          shopDeliveryMethodId: null,
          pickupPoint: null,
        }
      }

      await setFieldValue('productOrder', newProductOrder)
      await setFieldValue('deliveryAddressId', null)
      handleDeliveryTypeChange()
    }
    handleChange();
  }, [deliveryType])

  const handleFormChange = useCallback(({ nextValues }) => {
    updateStepState(nextValues);
  }, [updateStepState]);

  const selectShopDeliveryMethod = useCallback(async (shopId, deliveryMethodId) => {
    console.log('select shop delivery method', {shopId, deliveryMethodId})
    const newProductOrder = {...productOrder}
    newProductOrder[shopId].shopDeliveryMethodId = parseInt(deliveryMethodId)

    newProductOrder[shopId] = {
      ...newProductOrder[shopId],
    }

    await setFieldTouched('productOrder')
    await setFieldValue('productOrder', newProductOrder)
  })

  useEffect(() => {
    // if we're dealing with home delivery, we need to update the delivery rates according to the address
    if (deliveryType === DeliveryTypes.DELIVERY && selectedAddress) {
      setIsLoadingRates(true)
      dispatch(actions.deliveries.fetchDeliveryRates({
        countryId: selectedAddress.countryId,
        postalCode: selectedAddress.postalCode,
      })).then(response => {
        setIsLoadingRates(false)

        if (deliveryMethodsRef.current) {
          formRef.current.scrollTo({
            top: deliveryMethodsRef.current.offsetTop,
            behavior: 'smooth',
          })
        }

        const newProductOrder = {...values.productOrder}
        response.value.data.forEach(shopDeliveryRates => {
          const shopId = shopDeliveryRates.id
          const deliveryMethods = shopDeliveryRates.methods[deliveryType]

          if (!newProductOrder[shopId]) {
            newProductOrder[shopId] = {}
          }

          if (!deliveryMethods || deliveryMethods.length === 0) {
            // no rates means no delivery method available - remove
            newProductOrder[shopId].shopDeliveryMethodId = null
          } else if (deliveryMethods && deliveryMethods.length === 1) {
            newProductOrder[shopId].shopDeliveryMethodId = parseInt(deliveryMethods[0].id)
            setFieldTouched('productOrder')
          } else {
            // if there's more than one delivery method available, we need to reset the selected delivery method
            // we'll show the delivery method selection for each shop
            newProductOrder[shopId].shopDeliveryMethodId = null
          }
        })

        setFieldValue('productOrder', newProductOrder)
      })
    } else if (deliveryType === DeliveryTypes.PICKUP_POINT && pickupPoint && postalCode) {
      setIsLoadingRates(true)
      dispatch(actions.deliveries.fetchDeliveryRates({
        pickupPoint: pickupPoint,
      })).then(response => {
        setIsLoadingRates(false)

        if (deliveryMethodsRef.current) {
          formRef.current.scrollTo({
            top: deliveryMethodsRef.current.offsetTop,
            behavior: 'smooth',
          })
        }

        const newProductOrder = {...values.productOrder}
        response.value.data.forEach(shopDeliveryRates => {
          const shopId = shopDeliveryRates.id
          const deliveryMethods = shopDeliveryRates.methods[deliveryType]

          if (!newProductOrder[shopId]) {
            newProductOrder[shopId] = {}
          }

          if (!deliveryMethods || deliveryMethods.length === 0) {
            // no rates means no delivery method available - remove
            newProductOrder[shopId].shopDeliveryMethodId = null
          } else if (deliveryMethods && deliveryMethods.length === 1) {
            newProductOrder[shopId].shopDeliveryMethodId = parseInt(deliveryMethods[0].id)
            setFieldTouched('productOrder')
          }
        })

        setFieldValue('productOrder', newProductOrder)
      })
    }
  }, [deliveryType, selectedAddress, deliveryMethodsRef, pickupPoint, postalCode])

  const handleDeliveryTypeChange = useCallback(() => {
    // if only one option exists for the given type, set it as default
    // we won't render the delivery method selection unless there's more than 1

    const newProductOrder = {...productOrder}
    for (const shopId of shopIds) {
      if (!newProductOrder[shopId]) {
        newProductOrder[shopId] = {}
      }

      const deliveryMethods = availableDeliveryMethods.find(s => s.id === shopId)
      if (deliveryMethods && deliveryMethods.methods[deliveryType] && deliveryMethods.methods[deliveryType].length === 1) {
        newProductOrder[shopId].shopDeliveryMethodId = parseInt(deliveryMethods.methods[deliveryType][0].id)
      } else {
        newProductOrder[shopId].shopDeliveryMethodId = null
      }

      newProductOrder[shopId] = {
        ...newProductOrder[shopId],
      }
    }

    setFieldTouched('productOrder')
    setFieldValue('productOrder', newProductOrder)

  }, [deliveryType])

  const showNoDeliveryMethodsAvailable = !!selectedAddress && (!deliveryMethods || deliveryMethods.length === 0)

  useFormikEffect(handleFormChange, { values })

  onFormikProps({
    submitForm,
    isValid,
    isSubmitting,
    submitLabel: t("flow.continue"),
  });

  return (
    <div css={styles.formContainer}>
      <Form
        noValidate
        className="form padded-step-container"
        onSubmit={handleSubmit}
        css={styles.form}
        ref={formRef}
      >
        <Container>
          {!deliveryType && (
            <Form.Group>
              <Form.Label className="medium-text">
                {t("delivery.chooseDeliveryType")}
              </Form.Label>
              <ul css={styles.transportTypes}>
                {deliveryTypes.map((type) => (
                  <li key={type.type}>
                    <div css={styles.deliveryTypeItem} className={type.disabled ? 'disabled' : ''}>
                      <Form.Check
                        custom
                        type="radio"
                        id={type.type}
                        name="deliveryType"
                        disabled={type.disabled}
                        label={t(`delivery.type.${type.type}`)}
                        checked={values.deliveryType === type.type}
                        onChange={() => {
                          setFieldTouched("deliveryType")
                          setFieldValue("deliveryType", type.type)
                        }}
                        isInvalid={touched.deliveryType && !!errors.deliveryType}
                      />
                      <div className="prices">
                        <div className="price">
                          {type.disabled ? (
                            <>{t("delivery.unavailable")}</>
                          ) : (
                            <>
                              {type.startingFrom && (
                                <span className="startingFrom">{t("delivery.startingFrom")}</span>
                              )}
                              {formatPrice(type.price, "€", {minFractionDigits: 2})}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {type.disabled && (
                      <p className="ml-4 disabled">{t('delivery.unavailableForShops')} {type.disabledFor.map(s => s.shopName).join(', ')}</p>
                    )}
                  </li>
                ))}
              </ul>

              {deliveryTypes.some(t => t.disabled) && (
                <p>{t('delivery.unavailableForShopsNote')}</p>
              )}
            </Form.Group>
          )}

          {(deliveryType && selectedDeliveryType) && (
            <div css={styles.selectedDeliveryType}>
              <div
                css={styles.changeDeliveryType}
                onClick={() => {
                  setFieldValue("deliveryType", null)
                  setTouched({}, false)
                }}
              >
                {t('delivery.changeDeliveryType')}
              </div>
              <div css={styles.deliveryTypeItem}>
                <Form.Check
                  custom
                  type="radio"
                  id={selectedDeliveryType.type}
                  name="deliveryType"
                  label={t(`delivery.type.${selectedDeliveryType.type}`)}
                  checked={true}
                  readOnly
                />
                <div className="prices">
                  <div className="price">
                    {selectedDeliveryType.startingFrom && (
                      <span className="startingFrom">{t("delivery.startingFrom")}</span>
                    )}
                    {formatPrice(selectedDeliveryType.price, "€", {minFractionDigits: 2})}
                  </div>
                </div>
              </div>

              {(isDelivery && deliveryAddressId && deliveryMethods.length > 0) && (
                <div className="shopDeliveryMethods">
                  {deliveryMethods.length > 1 && (
                    <p>{t('delivery.deliveryCostIsAggregated')}</p>
                  )}

                  {deliveryMethods.map((shopDeliveryMethod) => (
                    <div key={`shop-available-method-${shopDeliveryMethod.id}`} className="shop">
                      <div className="top">
                        <span className="name">{shopDeliveryMethod.shopName}</span>
                        {typeof shopDeliveryMethod.price !== 'undefined' && shopDeliveryMethod.methods.length === 1 && (
                          <span className="price">{formatPrice(shopDeliveryMethod.price, '€', {minFractionDigits: 2})}</span>
                        )}
                      </div>
                      {shopDeliveryMethod.methods.length > 1 && (
                        <div css={styles.shopDeliveryMethodOptions}>
                          <p>{t('delivery.selectDeliveryMethod')}</p>
                          <ul className="options">
                            {shopDeliveryMethod.methods.map(method => (
                              <li key={`shop-method-${method.id}`} className="option">
                                <Form.Check
                                  custom
                                  type="radio"
                                  id={method.id}
                                  name={`shop-method-${method.id}`}
                                  label={method.name}
                                  checked={method.id === productOrder[shopDeliveryMethod.shopId].shopDeliveryMethodId}
                                  onChange={() => {
                                    selectShopDeliveryMethod(shopDeliveryMethod.shopId, method.id)
                                  }}
                                />
                                <span className="price">{formatPrice(method.costWithVat, '€', {minFractionDigits: 2})}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {!shopDeliveryMethod.available && (
                        <span className="unavailableNote">{t('delivery.deliveryMethodAddressUnavailableForShop')}</span>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {isPickupPoint && (
                <div className="shopDeliveryMethods">
                  <p>{t('delivery.deliveryCostIsAggregated')}</p>

                  {selectedDeliveryMethods && Object.values(selectedDeliveryMethods).map((shopDeliveryMethod) => (
                    <div key={`shop-pickup-address-${shopDeliveryMethod.shopId}`} className="shop">
                      <div className="top">
                        <span className="name">{shopDeliveryMethod.shopName}</span>
                        {typeof shopDeliveryMethod.costWithVat !== 'undefined' && (
                          <span className="price">{formatPrice(shopDeliveryMethod.costWithVat, '€', {minFractionDigits: 2})}</span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {isPickup && (
                <div className="shopDeliveryMethods">
                  <p>{t('delivery.shopPickupNote')}</p>

                  {selectedDeliveryMethods && Object.values(selectedDeliveryMethods).map((shopDeliveryMethod) => (
                    <div key={`shop-pickup-address-${shopDeliveryMethod.shopId}`} className="shop">
                      <div className="top">
                        <span className="name">{shopDeliveryMethod.shopName}</span>
                        {typeof shopDeliveryMethod.costWithVat !== 'undefined' && (
                          <span className="price">{formatPrice(shopDeliveryMethod.costWithVat, '€', {minFractionDigits: 2})}</span>
                        )}
                      </div>
                      <span>{shopDeliveryMethod.shopAddress}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {isDelivery && (
            <>
              <Form.Label className="medium-text">
                {t("reservation.deliveryAddress")}
              </Form.Label>
              <AddressSelection
                value={deliveryAddressId}
                onChange={async (address) => {
                  await setFieldTouched("deliveryAddressId")
                  if (address) {
                    await setFieldValue("deliveryAddressId", parseInt(address.id))
                  } else {
                    await setFieldValue("deliveryAddressId", null)
                  }
                }}
              />
            </>
          )}

          {(showNoDeliveryMethodsAvailable && !isLoadingRates) && (
            <div className="text-center">
              {t("delivery.noDeliveryMethodsAvailable")}
            </div>
          )}

          {isPickupPoint && (
            <PickupPointSearch
              pickupPointId={productOrder[Object.keys(productOrder)[0]].pickupPoint}
              onSetPickupPoint={async (pickupPoint) => {
                await setFieldTouched("productOrder")

                const newProductOrder = {...values.productOrder}
                for (const shopId of shopIds) {
                  if (!newProductOrder[shopId]) {
                    newProductOrder[shopId] = {}
                  }
                  newProductOrder[shopId].pickupPoint = pickupPoint.id
                }

                await setFieldValue('pickupPoint', pickupPoint.id)
                await setFieldValue('productOrder', newProductOrder)
                await setFieldValue("addressName", pickupPoint.name)
                await setFieldValue("street", pickupPoint.address)
                await setFieldValue("city", pickupPoint.city)
                await setFieldValue("postalCode", pickupPoint.postalCode)
              }}
            />
          )}
        </Container>
      </Form>
      <SummaryTotal />
    </div>
  );
}
