export const TransportTypes = {
  NONE: "none",
  PICKUP: "takeaway",
  DELIVERY: "delivery",
};
export const AllTransportTypes = Object.values(TransportTypes);

export const DeliveryTypes = {
  STORE_PICKUP: "STORE_PICKUP",
  PICKUP_POINT: "PICKUP_POINT",
  DELIVERY: "DELIVERY",
}
export const AllDeliveryTypes = Object.values(DeliveryTypes)

export const TransportInstants = {
  ASAP: "asap",
  SCHEDULED: "scheduled",
};
export const AllTransportInstants = Object.values(TransportInstants);

export const ReservationTypes = {
  NORMAL: "normal",
  SMART: "smart",
};
export const AllReservationTypes = Object.values(ReservationTypes);

export const ReservationModes = {
  BOOK_DATE: "book",
  BUY_VOUCHER: "voucher",
};
export const AllReservationModes = Object.values(ReservationModes);

export const PaymentMethods = {
  HIPAY_MBWAY: 'hipay_mbway',
  HIPAY_CC: 'hipay_cc',
  HIPAY_MB: 'hipay_mb'
}
export const AllPaymentMethods = Object.values(PaymentMethods);

